@import 'colors';
@import 'animation';

*,
::after,
::before {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	// border: 1px solid red;
}

html,
body {
	overflow-x: hidden;
	max-width: 100%;
}

html {
	scroll-padding-top: 60px;
}

body {
	position: relative;
	font-family: 'Roboto' sans-serif;
	background-color: $bg-color;
}

.shadow-bg {
	background-color: rgba(0, 0, 0, 0.8);
}

h2 {
	text-align: center;
	text-transform: uppercase;
	color: $red-color;
	margin-bottom: 50px;
}

.underline {
	width: 170px;
	height: 2px;
	background-color: $linie-color;
	margin: 0 auto 20px;
}

nav {
	text-transform: uppercase;
	font-size: 16px;
	padding: 10px;
	z-index: 1;
	transition: 0.6s;

	.navbar-brand {
		img {
			width: clamp(150px, 50vw, 350px);
		}
	}

	a.nav-link {
		position: relative;
		justify-content: space-evenly;
		width: 50%;
		margin-right: 10px;
		padding: 10px 20px;
		color: $white-color;
		transition: all 0.5s;
	}

	i.fas {
		color: $white-color;
		padding: 10px;
		font-size: 30px;
		border: 1px solid transparent;
	}

	i.fax {
		color: $white-color;
		padding: 10px;
		font-size: 30px;
		border: 1px solid transparent;
	}

	a.nav-link::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		bottom: -2px;
		right: -5px;
		border-bottom: 0 solid #fff;
		border-right: 0 solid #fff;
		transition: all 0.5s;
	}

	a.nav-link:hover {
		color: $red-color;
		box-shadow: inset 11px 6px 10px rgba(0, 0, 0, 0.5);
	}

	a.nav-link:hover::before {
		width: 100%;
		height: 100%;
	}
	a.nav-link:hover::before {
		border-bottom: 1px solid #fff;
		border-right: 1px solid #fff;
	}

	.active {
		color: $blue-color;
	}

	.hide {
		display: none;
	}
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:active:focus {
	box-shadow: none;
}

header {
	position: relative;
	color: $white-color;
	width: 100%;
	height: 90vh;

	h1 {
		padding: 10px;
		font-size: 30px;
		margin-top: 10px;
		text-transform: uppercase;
	}

	h1 span {
		color: $red-color;
	}

	i {
		padding-right: 5px;
		font-size: 40px;
		color: $fas-color;
		transition: 0.3s;
	}

	.btn {
		display: flex;
		text-transform: uppercase;
		border-radius: 20px;
		box-shadow: 0px 0px 12px -2px $btn-color;
		padding: 10px;
		transition: background-color 0.3s;
	}

	.btn:hover {
		color: $white-color;
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(8px);
	}

	p {
		font-size: 16px;
	}
}

.hero-img {
	position: relative;
	background-image: url('../img/mercedes-amg-gts-g30cafd667_640.jpg');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	width: 100%;
	// height: 100%;
	z-index: 0;

	.hero-shadow {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: -5;
	}

	.hero-text {
		// position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		z-index: 5;

		a {
			font-size: 20px;
		}
	}
}

.content {
	position: absolute;
	display: flex;
	justify-content: center;
	bottom: 10px;
	width: 100%;
	background: #ffffff1a;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);

	p {
		color: $white-color;
		font-size: 10px;
	}

	i {
		font-size: 10px;
		padding: 5px;
		color: $white-color;
	}

	.content-title {
		text-transform: uppercase;
		font-weight: bold;
	}
}

.about {
	.about-box {
		margin: 10px 20px;
		padding: 30px;
		background-color: #dadada;
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
		transition: transform 0.3s;
	}

	.about-box:hover {
		transform: scale(1.05);
	}

	h3 {
		text-transform: uppercase;
		color: $red-color;
	}

	i {
		font-size: 40px;
		padding-right: 20px;
	}
	p {
		font-size: 18px;
	}

	span {
		font-weight: bold;
	}

	.section-about {
		display: block;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.089);
		border-radius: 5px;
		margin: 1em;
		padding: 1em 0.5em;

		.text-header {
			text-transform: uppercase;
			text-align: center;
			font-weight: 700;
		}

		.text-one {
			text-align: start;
			margin-right: 1em;
		}
		.text-two {
			text-align: end;
			margin-left: 1em;
		}
		.text-three {
			text-align: start;
			margin-right: 1em;
			margin-bottom: 0;
		}
	}
}

.gallery {
	.cards {
		display: flex;
		height: 250px;
	}

	.card {
		background-position: center;
		background-size: cover;
		object-fit: cover;
		width: 50%;
		padding: 20px;
		transform-origin: top right;
		transition: 0.4s;
	}
	.card:not(:first-child) {
		margin-left: -4rem;
	}

	.card:hover {
		transform: scale(0.9);
	}

	.card:hover ~ .card {
		transform: translateX(4rem);
	}

	.text {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-top: 50%;
		text-align: center;
	}

	h1 {
		margin-bottom: 30px;
	}
	.one {
		background-image: url('../img/20180510_195902.jpg');
	}
	.two {
		background-image: url('../img/Classic2.jpg');
	}
	.three {
		background-image: url('../img/282749235_3038.jpg');
	}
	.four {
		background-image: url('../img/Pickup.jpg');
	}
	.five {
		background-image: url('../img/Traktor.jpg');
	}
	.six {
		background-image: url('../img/rusztowanie.jpg');
	}
	.seven {
		background-image: url('../img/UsArmyJep.jpg');
	}
}

.price {
	p {
		font-size: 1rem;
		font-weight: 700;
	}
	small {
		font-size: 1rem;
		font-family: roboto, 'sans-serif';
		font-weight: 400;
	}
	h3 {
		font-size: 1.2rem;
	}
}

.partners {
	&__logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;

		&--slider {
			img {
				max-width: 150px;
				width: 100%;
				transition: 0.3s ease-in-out;
			}
		}
		img:hover {
			transform: scale(1.1);
		}
	}
}

.contact {
	position: relative;
	background-image: url('../img/bridge-small_640.jpg');
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	z-index: 0;

	h2 {
		font-weight: bold;
		color: $white-color;
	}
	.underline {
		background-color: lightgray;
	}
	.contact-shadow {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: #0e223bcc;
		z-index: -5;
	}

	.btn-contact {
		text-decoration: none;
		cursor: pointer;
		padding: 5px;
		border: 2px solid $white-color;
		border-radius: 10px;
		transition: background-color 0.3s;
	}
	.btn-contact:hover {
		color: $white-color;
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(8px);
	}
}

.contact-us {
	display: flex;
	color: $white-color;

	.contact-item {
		margin: 0;
		a {
			padding: 10px;
			color: $white-color;
		}
		p {
			padding: 10px;
		}

		h3 {
			margin-bottom: 15px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}

		.social-media {
			font-size: 26px;
			margin-bottom: 10px;

			a:hover {
				color: lightgrey;
			}
		}
		.maps-box {
			iframe {
				margin: 0 auto;
				width: 100%;
				min-width: 100px;
				height: 300px;
			}
		}
	}
}

@media (min-width: 320px) {
	html {
		scroll-padding-top: 60px;
	}

	header {
		h1 {
			font-size: 32px;
		}
		p {
			padding: 10px;
			font-size: 18px;
		}

		i {
			font-size: 40px;
			padding-right: 10px;
		}
		.btn {
			display: flex;
			font-size: 28px;
			align-items: center;
		}
		.hero-img {
			height: 90vh;
		}

		.content {
			p {
				padding: 5px;
				font-size: 12px;
				margin-bottom: 0;
			}
			i {
				font-size: 28px;
			}
			.content-title {
				font-size: 12px;
				padding: 0 10px;
			}
		}
	}
}

@media (min-width: 768px) {
	html {
		scroll-padding-top: 60px;
	}

	img {
		width: 300px;
	}
	.hero-img {
		background-image: url('../img/mercedes-benz-amg-gts-g0f7f696f3_1280.jpg');
	}

	.contact {
		background-image: url('../img/bridge-g5b4254a1e_1280.jpg');
	}

	header {
		h1 {
			font-size: 42px;
		}

		.hero-text p {
			font-size: 38px;
		}

		.hero-img {
			.hero-text a {
				font-size: 38px;
			}
		}

		.content {
			.content-text {
				display: inline-block;
			}
			p {
				font-size: 18px;
			}
			i {
				font-size: 30px;
			}
			.content-title {
				font-size: 16px;
				padding: 0 5px;
			}
		}
	}

	.price {
		h3 {
			font-size: 20px;
		}
	}
}

@media (min-width: 992px) {
	header {
		.content {
			p {
				margin-bottom: 0;
			}
			i {
				font-size: 40px;
			}
			.content-title {
				font-size: 16px;
				padding: 0 5px;
			}
		}
	}
}
