.shake-br {
  animation: shake-br 1.2s linear 1000ms infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-26 22:45:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@keyframes shake-br {
  0%, 100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(2deg);
  }
  20%, 40%, 60% {
    transform: rotate(-4deg);
  }
  30%, 50%, 70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}
*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

html {
  scroll-padding-top: 60px;
}

body {
  position: relative;
  font-family: "Roboto" sans-serif;
  background-color: #c6c7ce;
}

.shadow-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

h2 {
  text-align: center;
  text-transform: uppercase;
  color: #eb2424;
  margin-bottom: 50px;
}

.underline {
  width: 170px;
  height: 2px;
  background-color: #606060;
  margin: 0 auto 20px;
}

nav {
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px;
  z-index: 1;
  transition: 0.6s;
}
nav .navbar-brand img {
  width: clamp(150px, 50vw, 350px);
}
nav a.nav-link {
  position: relative;
  justify-content: space-evenly;
  width: 50%;
  margin-right: 10px;
  padding: 10px 20px;
  color: #fff;
  transition: all 0.5s;
}
nav i.fas {
  color: #fff;
  padding: 10px;
  font-size: 30px;
  border: 1px solid transparent;
}
nav i.fax {
  color: #fff;
  padding: 10px;
  font-size: 30px;
  border: 1px solid transparent;
}
nav a.nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -2px;
  right: -5px;
  border-bottom: 0 solid #fff;
  border-right: 0 solid #fff;
  transition: all 0.5s;
}
nav a.nav-link:hover {
  color: #eb2424;
  box-shadow: inset 11px 6px 10px rgba(0, 0, 0, 0.5);
}
nav a.nav-link:hover::before {
  width: 100%;
  height: 100%;
}
nav a.nav-link:hover::before {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
nav .active {
  color: #00a2ff;
}
nav .hide {
  display: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:active:focus {
  box-shadow: none;
}

header {
  position: relative;
  color: #fff;
  width: 100%;
  height: 90vh;
}
header h1 {
  padding: 10px;
  font-size: 30px;
  margin-top: 10px;
  text-transform: uppercase;
}
header h1 span {
  color: #eb2424;
}
header i {
  padding-right: 5px;
  font-size: 40px;
  color: #24EBEB;
  transition: 0.3s;
}
header .btn {
  display: flex;
  text-transform: uppercase;
  border-radius: 20px;
  box-shadow: 0px 0px 12px -2px #f5c3c3;
  padding: 10px;
  transition: background-color 0.3s;
}
header .btn:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
}
header p {
  font-size: 16px;
}

.hero-img {
  position: relative;
  background-image: url("../img/mercedes-amg-gts-g30cafd667_640.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  z-index: 0;
}
.hero-img .hero-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -5;
}
.hero-img .hero-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  z-index: 5;
}
.hero-img .hero-text a {
  font-size: 20px;
}

.content {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 10px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1019607843);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}
.content p {
  color: #fff;
  font-size: 10px;
}
.content i {
  font-size: 10px;
  padding: 5px;
  color: #fff;
}
.content .content-title {
  text-transform: uppercase;
  font-weight: bold;
}

.about .about-box {
  margin: 10px 20px;
  padding: 30px;
  background-color: #dadada;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s;
}
.about .about-box:hover {
  transform: scale(1.05);
}
.about h3 {
  text-transform: uppercase;
  color: #eb2424;
}
.about i {
  font-size: 40px;
  padding-right: 20px;
}
.about p {
  font-size: 18px;
}
.about span {
  font-weight: bold;
}
.about .section-about {
  display: block;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.089);
  border-radius: 5px;
  margin: 1em;
  padding: 1em 0.5em;
}
.about .section-about .text-header {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}
.about .section-about .text-one {
  text-align: start;
  margin-right: 1em;
}
.about .section-about .text-two {
  text-align: end;
  margin-left: 1em;
}
.about .section-about .text-three {
  text-align: start;
  margin-right: 1em;
  margin-bottom: 0;
}

.gallery .cards {
  display: flex;
  height: 250px;
}
.gallery .card {
  background-position: center;
  background-size: cover;
  object-fit: cover;
  width: 50%;
  padding: 20px;
  transform-origin: top right;
  transition: 0.4s;
}
.gallery .card:not(:first-child) {
  margin-left: -4rem;
}
.gallery .card:hover {
  transform: scale(0.9);
}
.gallery .card:hover ~ .card {
  transform: translateX(4rem);
}
.gallery .text {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 50%;
  text-align: center;
}
.gallery h1 {
  margin-bottom: 30px;
}
.gallery .one {
  background-image: url("../img/20180510_195902.jpg");
}
.gallery .two {
  background-image: url("../img/Classic2.jpg");
}
.gallery .three {
  background-image: url("../img/282749235_3038.jpg");
}
.gallery .four {
  background-image: url("../img/Pickup.jpg");
}
.gallery .five {
  background-image: url("../img/Traktor.jpg");
}
.gallery .six {
  background-image: url("../img/rusztowanie.jpg");
}
.gallery .seven {
  background-image: url("../img/UsArmyJep.jpg");
}

.price p {
  font-size: 1rem;
  font-weight: 700;
}
.price small {
  font-size: 1rem;
  font-family: roboto, "sans-serif";
  font-weight: 400;
}
.price h3 {
  font-size: 1.2rem;
}

.partners__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.partners__logos--slider img {
  max-width: 150px;
  width: 100%;
  transition: 0.3s ease-in-out;
}
.partners__logos img:hover {
  transform: scale(1.1);
}

.contact {
  position: relative;
  background-image: url("../img/bridge-small_640.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
}
.contact h2 {
  font-weight: bold;
  color: #fff;
}
.contact .underline {
  background-color: lightgray;
}
.contact .contact-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(14, 34, 59, 0.8);
  z-index: -5;
}
.contact .btn-contact {
  text-decoration: none;
  cursor: pointer;
  padding: 5px;
  border: 2px solid #fff;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.contact .btn-contact:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
}

.contact-us {
  display: flex;
  color: #fff;
}
.contact-us .contact-item {
  margin: 0;
}
.contact-us .contact-item a {
  padding: 10px;
  color: #fff;
}
.contact-us .contact-item p {
  padding: 10px;
}
.contact-us .contact-item h3 {
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.contact-us .contact-item .social-media {
  font-size: 26px;
  margin-bottom: 10px;
}
.contact-us .contact-item .social-media a:hover {
  color: lightgrey;
}
.contact-us .contact-item .maps-box iframe {
  margin: 0 auto;
  width: 100%;
  min-width: 100px;
  height: 300px;
}

@media (min-width: 320px) {
  html {
    scroll-padding-top: 60px;
  }
  header h1 {
    font-size: 32px;
  }
  header p {
    padding: 10px;
    font-size: 18px;
  }
  header i {
    font-size: 40px;
    padding-right: 10px;
  }
  header .btn {
    display: flex;
    font-size: 28px;
    align-items: center;
  }
  header .hero-img {
    height: 90vh;
  }
  header .content p {
    padding: 5px;
    font-size: 12px;
    margin-bottom: 0;
  }
  header .content i {
    font-size: 28px;
  }
  header .content .content-title {
    font-size: 12px;
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  html {
    scroll-padding-top: 60px;
  }
  img {
    width: 300px;
  }
  .hero-img {
    background-image: url("../img/mercedes-benz-amg-gts-g0f7f696f3_1280.jpg");
  }
  .contact {
    background-image: url("../img/bridge-g5b4254a1e_1280.jpg");
  }
  header h1 {
    font-size: 42px;
  }
  header .hero-text p {
    font-size: 38px;
  }
  header .hero-img .hero-text a {
    font-size: 38px;
  }
  header .content .content-text {
    display: inline-block;
  }
  header .content p {
    font-size: 18px;
  }
  header .content i {
    font-size: 30px;
  }
  header .content .content-title {
    font-size: 16px;
    padding: 0 5px;
  }
  .price h3 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  header .content p {
    margin-bottom: 0;
  }
  header .content i {
    font-size: 40px;
  }
  header .content .content-title {
    font-size: 16px;
    padding: 0 5px;
  }
}