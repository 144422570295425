.shake-br {
  animation: shake-br 1.2s linear 1000ms infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-26 22:45:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */

@keyframes shake-br {
  0%,
  100% {
    transform: rotate(0deg);
    // transform-origin: 100% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}
